<template>
  <div class="bg-blue h-[60px] flex pt-[env(safe-area-inset-top)] sm:hidden" :class="is_drawer_open ? 'hidden' : '' "></div>

  <nav v-if="localePath" id="top-nav" class="w-full z-40 top-0 bg-white drop-shadow-sm">
    <div class="brace flex justify-between h-[--layout-header-height] transition-height duration-300">
      <div class="flex-auto lg:basis-1/4 lg:!flex" :class="{ hidden: search, flex: !search }">
        <FallbackLink class="flex-none inline-flex my-auto" :to="{ name: 'index' }">
          <img class="max-w-[130px] md:max-w-[170px]" alt="CQTS Logo" width="166" height="44" src="~assets/svg/logo-cqts-en.svg?inline" v-if="locale === 'en'" />
          <img class="max-w-[99px] md:max-w-[113px]" alt="Logo CQTS" width="124" height="38" src="~assets/svg/logo-cqts-fr.svg?inline" v-else />
        </FallbackLink>
      </div>

      <div class="flex-none lg:basis-2/4 flex justify-center gap-5 text-[0.9375rem]">
        <div class="flex-none flex" v-if="!search">
          <FallbackLink
            class="hidden uppercase my-auto font-semibold p-2 transition-all duration-300 border-0 border-green md:flex lg:pb-2 hover:border-b-6 aria-[current=page]:border-b-6"
            :to="{ name: 'information' }"
            >{{ t("information") }}
          </FallbackLink>
        </div>

        <div class="flex-none flex" v-if="!search">
          <FallbackLink
            class="flex-none hidden uppercase my-auto font-semibold p-2 transition-all duration-300 border-0 border-orange md:flex lg:pb-2 hover:border-b-6 aria-[current=page]:border-b-6"
            :to="{ name: 'prevention' }"
            >{{ t("prevention") }}
          </FallbackLink>
        </div>

        <div class="flex-none flex" v-if="!search" :to="{ name: 'quit' }">
          <FallbackLink
            class="flex-none hidden uppercase my-auto font-semibold p-2 transition-all duration-300 border-0 border-blue md:flex lg:pb-2 hover:border-b-6 aria-[current=page]:border-b-6"
            :to="{ name: 'quit' }"
            >{{ t("quit") }}
          </FallbackLink>
        </div>
      </div>

      <div class="flex-auto lg:basis-1/4 hidden lg:flex justify-end" :class="search ? '!flex' : ''">
        <form class="flex-none flex relative" method="GET" :action="localePath('search') || switchLocalePath('fr', { name: 'search' })">
          <input class="w-full h-12.25 my-auto px-6.5 pl-11 rounded-full bg-gray-50 placeholder:text-[0.875rem] focus:outline-none max-lg:placeholder:text-transparent"
                 id="search" type="search" name="q" ref="search" :placeholder="t('placeholder')" size="15" />

          <button class="absolute left-2 top-1/2 -translate-y-1/2 p-2 pointer-events-none" type="submit" :aria-label="t('search')">
            <img src="~assets/svg/search.svg?inline" :alt="t('search')" :title="t('search')" />
          </button>
        </form>

        <div class="flex-none hidden md:flex ml-4 -mr-2 my-auto *:p-2 *:capitalize *:text-white-400">
          <template v-for="loc in availableLocales" :key="loc.code">
            <a v-if="switchLocalePath(loc.code) !== switchLocalePath(loc.code, { name: 'index' })" :href="switchLocalePath(loc.code)">
              {{ loc.code }}
            </a>
            <a v-else :href="switchLocalePath(loc.code, { name: 'quit' })" @click.exact.prevent="preventLangChange()">
              {{ loc.code }}
            </a>
          </template>
        </div>
      </div>

      <div class="flex items-center md:hidden" v-if="!search">
        <button @click="search = !search">
          <img
            class="cursor-pointer pa-2 mr-6"
            src="~assets/svg/search.svg?inline"
            :alt="t('search')"
            :title="t('search')"
            v-if="is_drawer_open === false"
          />
        </button>

        <button v-if="is_drawer_open === false" @click="drawer" style="width: 33px">
          <img class="cursor-pointer pa-2" src="~assets/svg/burger.svg?inline" :alt="t('menu')" :title="t('menu')" />
        </button>
        <span v-else class="flex items-center justify-center">
          <button @click.prevent="is_drawer_open = false">
            <IconClose />
          </button>
        </span>
      </div>
    </div>
  </nav>

  <div class="z-50 w-full">
    <aside class="fixed top-[--layout-header-height] bottom-0 left-0 right-0 bg-white ease-in-out transition-all duration-300 z-30 overflow-x-hidden md:hidden"
           :class="is_drawer_open ? 'translate-x-0' : '-translate-x-full'">
      <div class="h-px bg-sable w-full"></div>
      <div class="relative min-h-full">
        <div v-if="locale === 'fr'">
          <list-stepper
            v-for="(step, index) in steppers"
            :key="step.title"
            :title="step.title"
            :title-to="step.link"
            :to="step.to"
            :closedClass="step.closedClass"
            title-class="font-semibold"
            class="mx-2.5"
            :open-class="step.background_category + ` ${step.id === 3 ? 'text-white' : ''}`"
            :aa-tab-index="index + 1"
          >
            <div class="flex w-full" v-if="index === 2">
              <button-quit></button-quit>
            </div>
            <list-stepper
              v-for="category in step.items"
              :key="category.id"
              :title="category.title"
              :title-to="category.link"
              :open-class="step.background_article + ' text-white'"
              :to="getTo(category)"
              :aa-tab-index="(index + 1) * 10"
            >
              <template #title="{ title }">
                <h3 class="font-normal pl-4 text-left" style="max-width: 95%">
                  {{ title }}
                </h3>
              </template>
              <nuxt-link
                v-for="(post, postIndex) in category.posts"
                :key="post.id"
                :to="post.link"
                class="flex items-center justify-between pl-8 pr-5 py-4 transition duration-300 rounded-lg font-normal"
                active-class="_font-bold"
                :tabindex="(index + 1) * 100 + postIndex"
                :target="post.blank ? '_blank' : ''"
              >
                {{ post.title }}
              </nuxt-link>
            </list-stepper>
          </list-stepper>
        </div>
        <div v-else>
          <div class="flex flex-col">
            <FallbackLink
              class="flex uppercase items-center justify-between font-bold px-5 py-4 mb-px w-full"
              :to="{ name: 'information' }"
            >
              {{ t("information") }}
              <arrow-small class="ml-2 stroke-current text-purple-600" width="7px" height="9px"></arrow-small>
            </FallbackLink>
            <FallbackLink
              class="flex uppercase items-center justify-between font-bold px-5 py-4 mb-px w-full"
              :to="{ name: 'prevention' }"
              >{{ t("prevention") }}
              <arrow-small class="ml-2 stroke-current text-purple-600" width="7px" height="9px"></arrow-small>
            </FallbackLink>
            <FallbackLink
              class="flex uppercase items-center justify-between font-bold px-5 py-4 mb-px w-full"
              :to="{ name: 'quit' }"
              >{{ t("quit") }}
              <arrow-small class="ml-2 stroke-current text-purple-600" width="7px" height="9px"></arrow-small>
            </FallbackLink>
          </div>
        </div>
        <div class="mx-2.5 *:flex *:items-center *:justify-between *:px-5 *:py-4 *:transition *:duration-300 *:mb-px *:rounded-lg *:bg-sable *:w-full">
          <template v-for="loc in availableLocales" :key="loc.code">
            <a v-if="switchLocalePath(loc.code) !== switchLocalePath(loc.code, { name: 'index' })" :href="switchLocalePath(loc.code)">
              <span class="text-gray-700 font-normal capitalize" tabindex="4">{{ loc.code }}</span>
              <ArrowSmall class="transition stroke-current text-gray-700 duration-300"></ArrowSmall>
            </a>
            <a v-else :href="switchLocalePath(loc.code, { name: 'quit' })" @click.exact.prevent="preventLangChange()">
              <span class="text-gray-700 font-normal capitalize" tabindex="4">{{ loc.code }}</span>
              <ArrowSmall class="transition stroke-current text-gray-700 duration-300"></ArrowSmall>
            </a>
          </template>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { ref } from "vue";

import FallbackLink from "~/components/FallbackLink.vue";
import ButtonQuit from "~/components/Buttons/ButtonQuit.vue";
import ListStepper from "~/components/Lists/ListStepper.vue";

import ArrowSmall from "~/assets/svg/arrow-small.svg";
import IconClose from "~/assets/svg/close.svg";

export default {
  components: {
    FallbackLink,
    ListStepper,
    ButtonQuit,
    IconClose,
    ArrowSmall,
  },
  setup() {
    const nuxtApp = useNuxtApp();
    const { t, locale, locales } = useI18n({
      useScope: "local",
    });
    const switchLocalePath = useSwitchLocalePath();
    const localePath = useLocalePath();
    const router = useRouter();
    const { information, prevention, quit } = nuxtApp.$store.state.web;

    const availableLocales = locales.value.filter((i) => i.code !== locale.value);

    return { t, locale: locale.value, localePath, router, information, prevention, quit, availableLocales, switchLocalePath, nuxtApp };
  },
  data() {
    return {
      search: false,
      is_drawer_open: false,
      steppers: [
        {
          id: 1,
          title: this.t("information"),
          background_category: "bg-green",
          background_article: "bg-green-200",
          items: this.information.items,
          closedClass: "text-base leading-[1.375rem] font-bold uppercase tracking-normal",
          to: this.localePath("information"),
          link: this.localePath("information"),
        },
        {
          id: 2,
          title: this.t("prevention"),
          background_category: "bg-orange",
          background_article: "bg-orange-200",
          items: this.prevention.items,
          closedClass: "text-base leading-[1.375rem] font-bold uppercase tracking-normal",
          to: this.localePath("prevention"),
          link: this.localePath("prevention"),
        },
        {
          id: 3,
          title: this.t("quit"),
          background_category: "bg-dark-blue",
          background_article: "bg-dark-blue-200",
          items: this.quit.items,
          closedClass: "text-base leading-[1.375rem] font-bold uppercase tracking-normal",
          to: this.localePath("quit"),
          link: this.localePath("quit"),
        },
      ],
    };
  },
  watch: {
    $route() {
      this.is_drawer_open = false;
    },
    search(value) {
      if (value) {
        this.$nextTick(() => this.$refs.search.focus());
      }
    },
    is_drawer_open: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.code === 27 && this.is_drawer_open) this.is_drawer_open = false;
    });
  },
  methods: {
    preventLangChange() {
      this.nuxtApp.$store.commit("preventLangChange", true);
    },
    getTo(category) {
      if (category.id === 114) {
        return this.localePath({ name: "information-subpage-index-category-news" });
      }

      // Le jour où ils vont rajouter des items, ca se deviendra inutile
      if (category.id === 117 && category.posts.length === 0) {
        return category.link;
      }

      if (category.id === 407) {
        return category.link;
      }

      return "";
    },
    drawer() {
      this.is_drawer_open = !this.is_drawer_open;
    },
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "information": "Je m'informe",
    "prevention": "Je préviens",
    "quit": "J'arrête",
    "search": "Recherche",
    "close-search": "Fermer la recherche",
    "placeholder": "Rechercher",
    "menu": "Ouvrir le menu"
  },
  "en": {
    "information": "Information",
    "prevention": "Prevention",
    "quit": "I quit now",
    "search": "Search",
    "close-search": "Close search",
    "placeholder": "Search",
    "menu": "Open menu"
  }
}
</i18n>
