<template>
  <button v-if="isButton" :disabled="disabled" :class="computedClass" @click="onclick">
    <slot></slot>
  </button>
  <a v-else :href="disabled ? undefined : linkTo" :class="computedClass">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "ButtonWeb",
  props: {
    klass: {
      type: String,
      required: false,
      default: "",
    },
    onclick: {
      type: Function,
      required: false,
      default: undefined,
    },
    linkTo: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClass() {
      return `flex justify-center items-center align py-3 px-3 text-center rounded-lg rounded-br-none hover:rounded-br-lg font-semibold ${this.klass}`;
    },
    isButton() {
      return !!this.onclick;
    },
  },
};
</script>
