export const saveCookiePreference = (data) => {
  localStorage.setItem("COOKIE_PREFERENCE", JSON.stringify(data));
};

export const isCookiePreferenceSaved = () => {
  if (typeof location === "undefined") {
    return true;
  }

  return !!localStorage.getItem("COOKIE_PREFERENCE");
};

export const refuseCookies = () => {
  saveCookiePreference({
    essentials: false,
    marketing: false,
    preference: false,
    statistics: false,
  });
};

export const fullCookieAcceptance = () => {
  saveCookiePreference({
    essentials: true,
    marketing: true,
    preference: true,
    statistics: true,
  });
};

export default {
  saveCookiePreference,
  isCookiePreferenceSaved,
  refuseCookies,
  fullCookieAcceptance,
};
