<template>
  <div v-if="t" class="flex flex-col h-full *:flex-none">
    <layout-header />
    <dialog-switch-lang ref="lang_switcher"></dialog-switch-lang>
    <div id="container-wrapper" class="!grow">
      <slot />
    </div>
    <layout-footer />
    <Banner />
  </div>
</template>
<script>
import { ref } from "vue";

import Banner from "~/components/Cookie/Banner";
import DialogSwitchLang from "~/components/Dialog/DialogSwitchLang";
import LayoutFooter from "~/components/Layout/LayoutFooter";
import LayoutHeader from "~/components/Layout/LayoutHeader";
import CanonicalMixin from "~/mixins/canonical-mixin";

export default {
  name: "LayoutDefault",
  components: {
    LayoutHeader,
    LayoutFooter,
    DialogSwitchLang,
    Banner,
  },
  setup() {
    const nuxtApp = useNuxtApp();
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const recaptchaKey = useRuntimeConfig().public.recaptcha;
    const scrolled = ref(false);

    useHead({
      bodyAttrs: {
        class: () => scrolled.value ? 'scrolled' : ''
      }
    });

    CanonicalMixin.setCanonical(locale.value);

    if (process.client) {
      if (window.recaptchaToRender == null) {
        window.recaptchaToRender = [];
        window.recaptchaScripts = [];
      }
    }
    router.beforeEach((to, from) => {
      if (process.client) {
        window.recaptchaToRender = [];
      }
    });
    router.afterEach((to, from) => {
      const scripts = Array.from(document.getElementsByTagName("script")).filter((s) => {
        const scriptTag = s.getAttribute("src");
        return scriptTag != null && scriptTag.indexOf("recaptcha") >= 0;
      });
      window.recaptchaScripts = scripts;
      if (window.recaptchaScripts.length > 0) {
        window.renderRecaptcha("route change");
      }
    });

    return { t, locale: locale.value, store: nuxtApp.$store, route, recaptchaKey, scrolled, nuxtApp };
  },
  middleware: [],
  head() {
    return {
      htmlAttrs: {
        lang: this.locale,
      },
    };
  },
  watch: {
    $route() {
      // For AA
      const resetFocusItem = document.getElementById("container-wrapper");
      resetFocusItem.setAttribute("tabindex", "0");
      resetFocusItem.focus();
    },
  },
  methods: {
    setupScript() {
      const scripts = Array.from(document.getElementsByTagName("script")).filter((s) => {
        const scriptTag = s.getAttribute("src");
        return scriptTag != null && scriptTag.indexOf("recaptcha") >= 0;
      });

      if (scripts.length > 0) return;

      return new Promise((resolve) => {
        const script = document.createElement("script");
        window.registerRecaptcha = (id) => {
          if (window.recaptchaToRender == null) {
            window.recaptchaToRender = [];
          }
          window.recaptchaToRender.push(id);
          if (window.recaptchaScripts.length > 0) {
            window.renderRecaptcha("register");
          }
        };

        window.renderRecaptcha = (origin = "setup") => {
          setTimeout(() => {
            window.recaptchaToRender.forEach((recaptchaId) => {
              window.recaptchaToRender = window.recaptchaToRender.filter((id) => {
                return recaptchaId == id;
              });
              window.grecaptcha.render(recaptchaId, {
                sitekey: this.recaptchaKey,
              });
            }, 3000);
          });
        };
        window.onRecaptchaLoaded = () => {
          window.renderRecaptcha();
          resolve();
        };
        script.type = "text/javascript";
        script.id = "recatpcha";
        script.defer = true;
        script.src = `https://www.google.com/recaptcha/api.js?hl=${this.locale}&onload=onRecaptchaLoaded&render=explicit`;
        document.head.appendChild(script);
      });
    },
  },
  mounted() {
    this.setupScript();

    if (process.client && window) {
      window.history.scrollRestoration = "auto";
    }

    this.scrolled = (document.documentElement.scrollTop || document.body.scrollTop || 0) > 0;
    window.addEventListener("scroll",
      () => { this.scrolled = (document.documentElement.scrollTop || document.body.scrollTop || 0) > 0; },
      { passive: true }
    );
  },
};
</script>

<style scoped></style>
