<template>
  <div class="w-full flex flex-col md:flex-row" :class="{ 'border-b border-black pb-4 mb-4': !borderLess }">
    <div class="flex-1">
      <div class="text-black text-lg font-bold">
        {{ title }}
      </div>

      <div class="mt-2 text-black text-base">
        {{ content }}
      </div>
    </div>

    <div class="mt-4 md:mt-0 md:ml-20">
      <Toggle :checked="checked" :on-change="handleChange" />
    </div>
  </div>
</template>

<script>
import Toggle from "~/components/Forms/Ui/Toggle.vue";

export default {
  name: "Preference",
  components: {
    Toggle,
  },
  props: {
    borderLess: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    onChange: {
      required: true,
      type: Function,
    },
  },
  methods: {
    handleChange(val) {
      this.$props.onChange(val);
    },
  },
};
</script>
