<template>
  <footer class="w-full mt-auto bg-black-100 text-white-400">
    <div class="brace">
      <ul class="flex max-md:flex-col md:flex-wrap justify-between py-5 lg-footer:py-4 text-sm gap-4 lg-footer:gap-10">
        <li>
          <a href="https://cqts.qc.ca/" target="_blank">&copy;{{ t("tips") }}</a>
        </li>
        <li>
          <FallbackLink :to="{ name: 'responsability' }">{{ t("responsibility") }}</FallbackLink>
        </li>
        <li>
          <FallbackLink :to="{ name: 'privacy-policy' }">{{ t("privacy-policy") }}</FallbackLink>
        </li>
        <li>
          <FallbackLink :to="{ name: 'personal-information-policy' }">{{ t("personal-information-policy") }}</FallbackLink>
        </li>
        <li>
          <FallbackLink :to="{ name: 'sitemap' }">{{ t("sitemap") }}</FallbackLink>
        </li>
        <li class="flex gap-2">
          <a class="-ml-2 px-2" target="_blank" href="https://www.facebook.com/quebecsanstabac/">
            <img alt="Facebook" class="inline-block w-6 h-6" src="~/assets/svg/fb2.svg?inline" />
          </a>

          <a class="-mr-2 px-2" target="_blank" href="https://www.instagram.com/quebec_sans_tabac/">
            <img alt="Instagram" class="inline-block w-6 h-6" src="~/assets/svg/instagram.svg?inline" />
          </a>
        </li>
      </ul>
      <div class="lg-footer:w-auto h-px bg-white/10 lg-footer:hidden"></div>
    </div>
  </footer>
</template>

<script>
import FallbackLink from "~/components/FallbackLink.vue";

export default {
  components: {
    FallbackLink,
  },
  setup() {
    const { t } = useI18n({
      useScope: "local",
    });
    return { t };
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "follow-us": "Suivez Québec sans tabac",
    "responsibility": "Conditions d'utilisation",
    "privacy-policy": "Politique de confidentialité",
    "personal-information-policy": "Politique des renseignements personnels",
    "logo": "Logo",
    "tips": "Conseil québécois sur le tabac et la santé",
    "sitemap": "Plan du site",
    "leeroy-alt": "LEEROY Agence Créative Digitale - Web & Marketing"
  },
  "en": {
    "follow-us": "Follow Québec sans tabac",
    "responsibility": "Terms of Use",
    "privacy-policy": "Privacy Policy",
    "personal-information-policy": "Personal Information Policy",
    "logo": "Logo",
    "tips": "Quebec Council on Tobacco and Health",
    "sitemap": "Sitemap",
    "leeroy-alt": "LEEROY Creative Agency -  Digital, Web & Marketing"
  }
}
</i18n>
